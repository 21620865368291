export const en = {
    home: "Home",
    signUp: "Sign Up",
    login: "Log in",
    getOtp: "Send 6 - Digit Code",
    back: "Back",
    verify: "Verify",
    verifyOtp: "Verify Code",
    submit: "Submit",
    clear: "Clear",
    register: "Register",
    aboutUs: "About Us",
    contactUs: "Contact Us",
    howItWorks: "How it Works",
    testimonials: "Testimonials",
    partners: "Partners",
    administrators: "Administrators",
    privateNetwork: "Private Network",
    dealers: "Dealers",
    existingdealers: "Existing Dealers",
    roleManagement: "Role Management",
    inventory: "My Inventory",
    bids: "Bid",
    product: "Listed Vehicles",
    update: "Update",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    add: "Add",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    loginSuccess: "Login Successful",
    signupSuccess: "Signup Successful",
    useraddedSuccess: "User Added Successful",
    somethingWentWrong: "Something Went Wrong",
    internalServerError: "Internal Server Error",
    pleaseSelectLicenseDocument: "Please Select License Document",
    fileSizeLimit: "File size exceeds the 6MB limit.",
    selectRole: "Select User",
    areYouSure: "Are you sure you want to save changes?",
    areYouWantToLogout: "Are you sure you want to log out?",
    areYouWantToDelete: "Are you sure you want to Delete?",
    areYouWantToActivate: "Are you sure you want to Activate?",
    areYouWantToInActivate: "Are you sure you want to InActive?",
    areYouWantToClose: "Are you sure you want to Close Request?",
    areYouWantToResendEmail: "Are you sure you want to resend this email request?",
    this_Action_only_once: "Please note, this action can only be performed once.",
    areYouWantToDeactivate: "Are you sure you want to Deactivate?",
    DeleteDealerAdmin: "Please select Dealer Admin before deleting.",
    dontHaveAccount: "Don't have an account?",
    getBackToYou: "Thank you for reaching out! Your message has been successfully submitted.",
    approveUser: "Approve User",
    dashboard: "You are not allowed to access this page.",
    addNote: "Add  Note",
    associateWithDealer: "Associate With Other Dealership",
    selectDealership: "Select Dealership",
    Road_Dealer_Admins: "Road Dealer Admins",
    Select_Status: "Select Status",
    Vehicle_Requests: "Vehicle Requests",
    SelectPrivateNetwork: "Select Private Network",
    PrivateToPublic: "Are you Sure You Want to Post to Public?",
    PublicToPrivate: "Are you Sure You Want to Post to Private?"
};
