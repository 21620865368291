export const getSearchData = (searchInput: any, dataList: any) => {
    let searchedData: any = [];

    if (searchInput) {
        searchedData = dataList?.filter(
            (e: any) =>
                e?.FullName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.First_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Last_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Phone_No?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Email?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.PN_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // Private Network
                e?.Address_1?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Address_2?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.City?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Country?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.State?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.TimeZone?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Zipcode?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Geo_Location?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // Dealers
                e?.Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MainContact?.Email?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MainContact?.First_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MainContact?.Last_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MainContact?.Phone_No?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // BuyFigure
                e?.Year?.toString()?.includes(searchInput?.toLowerCase()) ||
                e?.Make?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Model?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.OdometerReading?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Trim?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.PhoneNumber?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Status?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.VIN?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // Inventory
                e?.Body?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ExteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.InteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.StockNo?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.TransName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.RDPrice?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // Product
                e?.ProductList?.Year?.toString()?.includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.Make?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.Model?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Email?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.MainContact?.First_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.MainContact?.Last_Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.MainContact?.Phone_No?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.OdometerReading?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.Trim?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.PhoneNumber?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.VIN?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.Body?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.ExteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.InteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.StockNo?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.TransName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.ProductList?.RDPrice?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // ReachOuts
                e?.MinYear?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MaxYear?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MinMiles?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MaxMiles?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MinPrice?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.MaxPrice?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Type?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Color?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Description?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                // MyBids
                e?.Inventory?.[0]?.Year?.toString()?.includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.Make?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.Model?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.VIN?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.StockNo?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.InteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Inventory?.[0]?.ExteriorColor?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Address_1?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Address_2?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.City?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.Country?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.Dealers?.[0]?.State?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.InitialPrice?.toLowerCase().includes(searchInput?.toLowerCase()) ||
                e?.CurentPrice?.toLowerCase().includes(searchInput?.toLowerCase())
        );
    } else {
        searchedData = dataList;
    }

    return searchedData;
};
