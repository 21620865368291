import { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";

import { InputAdornment, TextField, Tooltip } from "@mui/material";

import { getSearchData } from "../SearchField/logic";

const SearchFiledWithButton = ({ style, dataList, searchDatas, searchInputData }: any) => {
    const [data, setData] = useState(dataList);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = () => {
        searchInputData(searchTerm);

        const filteredData = getSearchData(searchTerm, dataList);

        setData(filteredData);
    };

    // Function to handle "Enter" key press
    const handleKeyPress = (e: any) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    useEffect(() => {
        searchDatas(data);
    }, [data]);
    return (
        <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Enter then click"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e?.target.value)}
            onKeyDown={handleKeyPress}
            sx={[
                {
                    backgroundColor: "#fff",
                    border: "none",
                    "& .MuiOutlinedInput-root": {
                        // - The Input-root, inside the TextField-root
                        "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "#ffffff00" // - Set the Input border
                        },
                        "&:hover fieldset": {
                            borderColor: "#ffffff00" // - Set the Input border when parent has :hover
                        },
                        "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "#ffffff00"
                        },
                        "& input": {
                            fontSize: "14px"
                        }
                    }
                },
                style
            ]}
            InputProps={{
                endAdornment: (
                    <Tooltip title="Click for Search">
                        <InputAdornment onClick={handleSearch} position="end" style={{ cursor: "pointer" }}>
                            <GoSearch />
                        </InputAdornment>
                    </Tooltip>
                )
            }}
        />
    );
};

export default SearchFiledWithButton;
