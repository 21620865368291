export const productBidsForm = [
    {
        Label: "",
        Required: true,
        Name: "First_Name",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Name",
        ErrorMessage: "Name is required*",
        Tooltip: "Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        Header: "First Name",
        PlaceHolder: "Enter Name",
        Disabled: false
    },
    {
        Label: "",
        Required: true,
        Name: "Last_Name",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Last Name",
        ErrorMessage: "Last Name is required*",
        Tooltip: "Last Name",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        Header: "Last Name",
        PlaceHolder: "Enter Last Name",
        Disabled: false
    },
    {
        Label: "",
        Required: true,
        Name: "Phone_No",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Phone Number",
        ErrorMessage: "Phone Number is required*",
        Tooltip: "Phone Number",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        Header: "Phone Number",
        PlaceHolder: "Enter Number",
        Disabled: false
    },
    {
        Label: "",
        Required: true,
        Name: "Email",
        InitialValue: "",
        Regex: ["^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\\.)+[a-zA-Z]{2,}[a-zA-Z.]*$"],
        RegExError: "Please enter Email",
        ErrorMessage: "Email is required*",
        Tooltip: "Email",
        HelperText: "",
        Type: "text",
        List: null,
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        Header: "Email",
        PlaceHolder: "Enter Email",
        Disabled: false
    },
    {
        Label: "",
        Required: true,
        Name: "Dealership",
        InitialValue: "",
        Regex: [],
        RegExError: "Please Select Dealership",
        ErrorMessage: "Dealership is required*",
        Tooltip: "Role Name",
        HelperText: "",
        Type: "select",
        List: [],
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: null,
        MaxValue: null,
        Multiple: 0,
        Header: "Select Dealership",
        PlaceHolder: "Select Dealership",
        Disabled: false,
        ReadOnly: false
    },
    {
        Label: "",
        Required: true,
        Name: "CurentPrice",
        InitialValue: "",
        Regex: [],
        RegExError: "Please enter Bid Amount",
        ErrorMessage: "Bid Amount is required*",
        Tooltip: "Bid Amount",
        HelperText: "",
        Type: "number",
        List: null,
        Categery: "Product_Form",
        Group: "Product_Details",
        MinValue: "0",
        MaxValue: null,
        Multiple: 0,
        Header: "Bid Amount (USD)",
        PlaceHolder: "Enter Offer",
        Disabled: false,
        Amount: true,
        AdditionalData: {
            "& .MuiInputBase-adornedEnd": {
                borderRadius: 2
            }
        }
    }
];
