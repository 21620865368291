enum RoutesEnum {
    home = "/",
    dashboard = "/dashboard",
    contactUs = "/contact-us",
    aboutUs = "/about-us",
    verifyOtp = "/verifyOtp",
    administrators = "/administrators",
    dealers = "/dealers",
    createDealers = "/dealers/createdealers",
    roleManagement = "rolemanagement",
    inventory = "/inventory",
    addInventory = "/inventory/addinventory",
    bids = "/bids",
    testing = "/testing",
    chat = "/chat",
    createUser = "/dealers/createuser",
    bidsDetails = "/bids/details",
    product = "/product",
    productDetails = "/product/details",
    myBids = "/mybids",
    dealerAdmin = "/dealerAdmin",
    viewDealerAdmin = "/dealerdetails",
    reachOut = "/reach-out",
    reachOutRequests = "/reachOutRequests",
    myGarage = "/mygarage",
    buyFigure = "/buyfigure",
    addBuyFigure = "/addBuyFigure",
    buyFigureDetails = "/buyfigure/details",
    privateNetworks = "/privatenetworks",
    addprivateNetworks = "/addprivatenetworks"
}
export default RoutesEnum;
